import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TranslateUp from "../utils/translateUp"
import TextAndImage from "../components/contentful/textAndImage"
import Cta from "../components/contentful/cta"
import Text from "../components/contentful/text"
import Summary from "../components/contentful/summary"
import AssociatedGuides from "../components/contentful/associatedGuides"
import Links from "../components/contentful/links"
import SEO from "../components/seo"

const Projects = props => {
  const { contentfulProject } = props.data
  const { title, categories } = contentfulProject
  const modules = props.data.contentfulProject.modules
  const hadSummary =
    props.data.contentfulProject.modules &&
    !!props.data.contentfulProject.modules.filter(
      module => module.__typename === "ContentfulShowSummary"
    )

  useEffect(() => {
    window.scrollTo({
      top: 2,
      behavior: "smooth",
    })
  }, [])

  const renderModulesOnPages = () => {
    return (
      modules &&
      modules.map((module, i) => {
        switch (module.__typename) {
          case "ContentfulShowSummary":
            return <Summary modules={modules} key={i} categories={categories} />
          case "ContentfulLinks":
            return (
              <Links
                title={module.title}
                oneColumn={module.oneColumn}
                twoColumns={module.twoColumns}
                key={i}
              />
            )
          case "ContentfulCta":
            return (
              <Cta
                categories={categories}
                text={module.text}
                link={module.ctaLink}
                label={module.ctaLabel}
                key={i}
              />
            )
          case "ContentfulTextModule":
            return (
              <Text
                id={module.id}
                position={module.position}
                categories={categories}
                title={module.title}
                subTitle={module.subTitle}
                oneColumn={module.oneColumn && module.oneColumn.oneColumn}
                twoColumns={module.twoColumns && module.twoColumns.twoColumns}
                key={i}
              />
            )
          case "ContentfulTextAndImage":
            return (
              <TextAndImage
                id={module.id}
                position={module.position}
                categories={categories}
                title={module.title}
                subTitle={module.subTitle}
                text={module.text.text}
                image={module.image}
                key={i}
              />
            )
          case "ContentfulAssociatedProjects":
            return (
              <AssociatedGuides
                id={module.id}
                title={module.title}
                associatedProjects={module.associatedProjects}
                categories={module.categories}
                key={i}
              />
            )

          default:
            return null
        }
      })
    )
  }

  console.log(props)
  return (
    <Layout
      categories={categories}
      purchaseGuideTitle={title}
      hadSummary={hadSummary}
    >
      <SEO title={title} />
      {renderModulesOnPages()}
    </Layout>
  )
}

export default TranslateUp(Projects)

export const pagequerypagetemplatebyslug = graphql`
  query contentfulpagetemplatebyslug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      title
      slug
      categories
      modules {
        __typename
        ... on Node {
          ... on ContentfulTextAndImage {
            id
            description
            title
            subTitle
            text {
              text
            }
            position
            image {
              description
              fluid {
                ...GatsbyContentfulFluid
              }
            }
          }
          ... on ContentfulCta {
            id
            description
            ctaLink
            ctaLabel
          }
          ... on ContentfulTextModule {
            id
            description
            title
            subTitle
            oneColumn {
              oneColumn
            }
            twoColumns {
              twoColumns
            }
          }
          ... on ContentfulAssociatedProjects {
            id
            description
            title
            associatedProjects {
              title
              slug
              categories
              image {
                description
                fluid(maxWidth: 130) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
          ... on ContentfulLinks {
            id
            description
            title
            oneColumn {
              oneColumn
            }
            twoColumns {
              twoColumns
            }
          }
        }
      }
    }
  }
`
