import React from "react"
import { Container, Content, SubContainer } from "./styled"
import { Wrapper } from "../../../styled"

import ReactMarkdown from "react-markdown"

const Text = props => {
  return (
    <Wrapper id={props.id}>
      <Container
        categories={props.categories}
        position={props.position}
        hasTwoColumns={!!props.twoColumns}
      >
        <div>
          <h1>{props.title}</h1>
          <h2>{props.subTitle}</h2>
        </div>

        <SubContainer
          position={props.position}
          hasTwoColumns={!!props.twoColumns}
        >
          <Content position={props.position} hasTwoColumns={!!props.twoColumns}>
            <div>
              <ReactMarkdown
                source={props.oneColumn}
                renderers={{
                  link: props => (
                    <a
                      href={props.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.children}
                    </a>
                  ),
                }}
                escapeHtml={false}
              />
            </div>

            {props.twoColumns && (
              <div>
                <ReactMarkdown
                  source={props.twoColumns}
                  renderers={{
                    link: props => (
                      <a
                        href={props.href}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {props.children}
                      </a>
                    ),
                  }}
                  escapeHtml={false}
                />
              </div>
            )}
          </Content>
        </SubContainer>
      </Container>
    </Wrapper>
  )
}

export default Text
