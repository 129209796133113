import React from "react"
import { Link } from "gatsby"

import { Container, Links } from "./styled"

const Summary = props => {
  return (
    <Container categories={props.categories}>
      <h1>Sommaire</h1>
      <Links>
        {props.modules.map((module, i) => {
          return (
            i > 0 && (
              <Link to={`#${module.id}`} key={i}>
                <span>{i}.</span>
                <li>{module.description}</li>
              </Link>
            )
          )
        })}
      </Links>
    </Container>
  )
}
export default Summary
