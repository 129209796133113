import React from "react"
import { Container, SubContainer } from "./styled"

import Guide from "../guides"
const ListGuides = props => {
  console.log(props, "PROPS")

  return (
    <Container id={props.id}>
      <h1>{props.title}</h1>

      <SubContainer>
        {props.associatedProjects.map((project, i) => {
          return <Guide key={i} guide={project} />
        })}
      </SubContainer>
    </Container>
  )
}
export default ListGuides
