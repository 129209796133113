import React from "react"
import { Container, Content, SubContainer } from "./styled"
import { Wrapper } from "../../../styled"
import ReactMarkdown from "react-markdown"
import TranslateUp from "../../../utils/translateUp"
import Img from "gatsby-image"
const TextAndImage = props => {
  return (
    <Wrapper isLarge={props.position === "ImageDown"} id={props.id}>
      <Container categories={props.categories} position={props.position}>
        <SubContainer position={props.position}>
          <Content position={props.position}>
            <div>
              {props.title && <h1>{props.title}</h1>}
              {props.subTitle && <h2>{props.subTitle}</h2>}
            </div>
            <ReactMarkdown
              source={props.text}
              renderers={{
                link: props => (
                  <a
                    href={props.href}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    {props.children}
                  </a>
                ),
              }}
              escapeHtml={false}
            />
          </Content>

          <Img
            fluid={props.image.fluid}
            loading="eager"
            fadeIn={false}
            alt={props.image.description || "Agorastore illustration"}
          />
        </SubContainer>
      </Container>
    </Wrapper>
  )
}

export default TranslateUp(TextAndImage)
